/* Explanation and examples - https://dev.azure.com/sticomputer/Apollo/_wiki/wikis/Apollo.wiki/172/Styles */

@use "./../helpers/functions";
@use "./../helpers/variables";

/// <reference path="./../helpers/functions" />
/// <reference path="./../helpers/variables" />

// Border
@mixin border-standard($top: null, $right: null, $bottom: null, $left: null) {
    @extend %border-standard;

    @if $top or $right or $bottom or $left {
        border-width: 0;
    }

    @if not $top and not $right and not $bottom and not $left {
        border-width: variables.$border-width;
    }

    @if $top {
        border-top-width: $top;
    }

    @if $right {
        border-right-width: $right;
    }

    @if $bottom {
        border-bottom-width: $bottom;
    }

    @if $left {
        border-left-width: $left;
    }
}

@mixin border-standard-rounded($top: null, $right: null, $bottom: null, $left: null) {
    @extend %border-standard-rounded;

    @if $top or $right or $bottom or $left {
        border-width: 0;
    }

    @if not $top and not $right and not $bottom and not $left {
        border-width: variables.$border-width;
    }

    @if $top {
        border-top-width: $top;
    }

    @if $right {
        border-right-width: $right;
    }

    @if $bottom {
        border-bottom-width: $bottom;
    }

    @if $left {
        border-left-width: $left;
    }
}

// Flex
@mixin flex(
    $direction: row,
    $align-items: null,
    $justify-content: null,
    $flex-wrap: null,
    $flex-grow: null,
    $flex-shrink: null,
    $flex-basis: null,
    $align-content: null,
    $column-gap: null,
    $row-gap: null
) {
    @extend %display-flex;
    flex-direction: $direction;
    flex-wrap: $flex-wrap;
    flex-grow: $flex-grow;
    flex-shrink: $flex-shrink;
    align-items: $align-items;
    align-content: $align-content;
    flex-basis: $flex-basis;
    justify-content: $justify-content;
    column-gap: $column-gap;
    row-gap: $row-gap;
}

@mixin flex-item-position-end($side: null) {
    @if $side == "top" {
        margin: auto 0 0 0;
    } @else if $side == "right" {
        margin: 0 auto 0 0;
    } @else if $side == "bottom" {
        margin: 0 0 auto 0;
    } @else if $side == "left" {
        margin: 0 0 0 auto;
    } @else if not $side {
        margin: auto;
    } @else {
        @error "Unknown side #{$side}";
    }
}

// Font
@mixin font-scale($size: "md") {
    @if $size == "xs" {
        font-size: functions.calc-font-scale(0.8);
    } @else if $size == "sm" {
        font-size: functions.calc-font-scale(0.9);
    } @else if $size == "md" {
        font-size: variables.$font-size-standard;
    } @else if $size == "lg" {
        font-size: functions.calc-font-scale(1.1);
    } @else if $size == "xl" {
        font-size: functions.calc-font-scale(1.5);
    } @else if $size == "xxl" {
        font-size: functions.calc-font-scale(2);
    } @else {
        @error "Unknown size #{$size}";
    }
}

@mixin font-scale-responsive($size: null) {
    @if $size == "xs" {
        font-size: functions.calc-font-scale-responsive(0.5);
    } @else if $size == "sm" {
        font-size: functions.calc-font-scale-responsive(0.75);
    } @else if $size == "md" {
        font-size: functions.calc-font-scale-responsive(1);
    } @else if $size == "lg" {
        font-size: functions.calc-font-scale-responsive(2);
    } @else if $size == "xl" {
        font-size: functions.calc-font-scale-responsive(3);
    } @else if $size == "xxl" {
        font-size: functions.calc-font-scale-responsive(4);
    } @else if not $size {
        font-size: variables.$font-size-responsive;
    } @else {
        @error "Unknown size #{$size}";
    }
}

@mixin truncate() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: normal;
}

// Form
@mixin form-col-width($ratio: 1) {
    width: functions.calc-form-col-width($ratio);
    flex-basis: functions.calc-form-col-width($ratio);
    min-width: functions.calc-form-col-min-width(variables.$min-input-width, calc(12 / #{$ratio}));
}

// Icon
@mixin icon-scale($size: "md") {
    @if $size == "xs" {
        font-size: functions.calc-icon-scale(-8px);
    } @else if $size == "sm" {
        font-size: functions.calc-icon-scale(-4px);
    } @else if $size == "md" {
        font-size: variables.$icon-size;
    } @else if $size == "lg" {
        font-size: functions.calc-icon-scale(4px);
    } @else if $size == "xl" {
        font-size: functions.calc-icon-scale(8px);
    } @else if $size == "xxl" {
        font-size: functions.calc-icon-scale(12px);
    } @else if $size == "xxxl" {
        font-size: functions.calc-icon-scale(16px);
    } @else {
        @error "Unknown size #{$size}";
    }
}

@mixin icon-button-scale($size: "md") {
    $font-size: variables.$icon-size;
    $offset: 12px;

    @if $size == "xs" {
        $font-size: functions.calc-icon-scale(-8px);
        $offset: 8px;
    } @else if $size == "sm" {
        $font-size: functions.calc-icon-scale(-4px);
        $offset: 10px;
    } @else if $size == "md" {
        $font-size: variables.$icon-size;
    } @else if $size == "lg" {
        $font-size: functions.calc-icon-scale(4px);
        $offset: 14px;
    } @else if $size == "xl" {
        $font-size: functions.calc-icon-scale(8px);
        $offset: 16px;
    } @else if $size == "xxl" {
        $font-size: functions.calc-icon-scale(12px);
        $offset: 18px;
    } @else if $size == "xxxl" {
        $font-size: functions.calc-icon-scale(16px);
        $offset: 20px;
    } @else {
        @error "Unknown size #{$size}";
    }

    font-size: $font-size;
    width: calc(#{$font-size} + #{$offset});
    height: calc(#{$font-size} + #{$offset});
}

@mixin icon-button-scale-responsive($size: "md") {
    $font-size: variables.$font-size-responsive;
    $offsetScale: 0;

    @if $size == "xs" {
        $font-size: functions.calc-icon-scale-responsive(0.5);
        offsetscale: 0.25;
    } @else if $size == "sm" {
        $font-size: functions.calc-icon-scale-responsive(0.75);
        $offsetScale: 0.375;
    } @else if $size == "md" {
        $font-size: variables.$icon-size-responsive;
        $offsetScale: 0.5;
    } @else if $size == "lg" {
        $font-size: functions.calc-icon-scale-responsive(2);
        $offsetScale: 1;
    } @else if $size == "xl" {
        $font-size: functions.calc-icon-scale-responsive(3);
        $offsetScale: 1.5;
    } @else if $size == "xxl" {
        $font-size: functions.calc-icon-scale-responsive(4);
        $offsetScale: 2;
    } @else if $size == "xxxl" {
        $font-size: functions.calc-icon-scale-responsive(5);
        $offsetScale: 2.5;
    } @else {
        @error "Unknown size #{$size}";
    }

    font-size: $font-size;
    width: calc(#{$font-size} + #{functions.calc-icon-scale-responsive($offsetScale)});
    height: calc(#{$font-size} + #{functions.calc-icon-scale-responsive($offsetScale)});
}

// Image
@mixin image-size($offset: 0) {
    height: calc(#{variables.$icon-size} + #{$offset});
    width: calc(#{variables.$icon-size} + #{$offset});
}

// Media Queries
@mixin small-width {
    @media (max-width: #{variables.$medium-screen-width - 1px}) {
        @content;
    }
}

@mixin medium-width {
    @media (min-width: #{variables.$medium-screen-width}) and (max-width: #{variables.$large-screen-width - 1px}) {
        @content;
    }
}

@mixin large-width {
    @media (min-width: #{variables.$large-screen-width}) {
        @content;
    }
}

// Margin
@mixin margin-standard($top: false, $right: false, $bottom: false, $left: false) {
    @if $top {
        margin-top: variables.$space-standard;
    }

    @if $right {
        margin-right: variables.$space-standard;
    }

    @if $bottom {
        margin-bottom: variables.$space-standard;
    }

    @if $left {
        margin-left: variables.$space-standard;
    }

    @if not $top and not $right and not $bottom and not $left {
        margin: variables.$space-standard;
    }
}

@mixin margin-standard-large($top: false, $right: false, $bottom: false, $left: false) {
    $margin: functions.calc-space-standard-scale(2);

    @if $top {
        margin-top: $margin;
    }

    @if $right {
        margin-right: $margin;
    }

    @if $bottom {
        margin-bottom: $margin;
    }

    @if $left {
        margin-left: $margin;
    }

    @if not $top and not $right and not $bottom and not $left {
        margin: $margin;
    }
}

@mixin margin-standard-small($top: false, $right: false, $bottom: false, $left: false) {
    $margin: functions.calc-space-standard-scale(0.5);

    @if $top {
        margin-top: $margin;
    }

    @if $right {
        margin-right: $margin;
    }

    @if $bottom {
        margin-bottom: $margin;
    }

    @if $left {
        margin-left: $margin;
    }

    @if not $top and not $right and not $bottom and not $left {
        margin: $margin;
    }
}

// Padding
@mixin padding-standard($top: false, $right: false, $bottom: false, $left: false) {
    @if $top {
        padding-top: variables.$space-standard;
    }

    @if $right {
        padding-right: variables.$space-standard;
    }

    @if $bottom {
        padding-bottom: variables.$space-standard;
    }

    @if $left {
        padding-left: variables.$space-standard;
    }

    @if not $top and not $right and not $bottom and not $left {
        padding: variables.$space-standard;
    }

    box-sizing: border-box;
}

@mixin padding-standard-large($top: false, $right: false, $bottom: false, $left: false) {
    $padding: functions.calc-space-standard-scale(2);

    @if $top {
        padding-top: $padding;
    }

    @if $right {
        padding-right: $padding;
    }

    @if $bottom {
        padding-bottom: $padding;
    }

    @if $left {
        padding-left: $padding;
    }

    @if not $top and not $right and not $bottom and not $left {
        padding: $padding;
    }

    box-sizing: border-box;
}

@mixin padding-standard-small($top: false, $right: false, $bottom: false, $left: false) {
    $padding: functions.calc-space-standard-scale(0.5);

    @if $top {
        padding-top: $padding;
    }

    @if $right {
        padding-right: $padding;
    }

    @if $bottom {
        padding-bottom: $padding;
    }

    @if $left {
        padding-left: $padding;
    }

    @if not $top and not $right and not $bottom and not $left {
        padding: $padding;
    }

    box-sizing: border-box;
}

// Position
@mixin position-absolute($top: null, $right: null, $bottom: null, $left: null) {
    position: absolute;

    @if not $top and not $right and not $bottom and not $left {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    @if $top {
        top: $top;
    }

    @if $right {
        right: $right;
    }

    @if $bottom {
        bottom: $bottom;
    }

    @if $left {
        left: $left;
    }
}
