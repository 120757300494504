@use "@angular/material" as mat;

@mixin button-theme($theme) {
    app-icon-button {
        button {
            &:hover {
                color: mat.get-theme-color($theme, primary, 50);
            }

            &.x-small {
                @include mat.button-density(-4);
                --mdc-icon-button-icon-size: 10px;
                --mdc-icon-button-state-layer-size: 20px;
            }

            &.small {
                @include mat.button-density(-2);
                --mdc-icon-button-icon-size: 14px;
                --mdc-icon-button-state-layer-size: 24px;
            }

            &.medium {
                @include mat.button-density(0);
                --mdc-icon-button-icon-size: 18px;
                --mdc-icon-button-state-layer-size: 28px;
            }
        }
    }

    .contrast-icon {
        app-icon-button {
            --mat-icon-button-state-layer-color: #{mat.get-theme-color($theme, primary, 100)};
            --mat-icon-button-hover-state-layer-opacity: 0.8;

            button {
                color: mat.get-theme-color($theme, primary, 100);

                &:hover {
                    mat-icon {
                        color: mat.get-theme-color($theme, primary, 40);
                    }
                }
            }
        }
    }
}
