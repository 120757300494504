@use "helpers/mixins";
@use "helpers/variables";
@use "themes/theme";

gridster {
    background: theme.$page-background !important;

    .gridster-column,
    .gridster-row {
        border-color: theme.$border-color !important;
    }

    gridster-item {
        border-radius: variables.$border-radius;
        overflow: visible !important;
    }
}
