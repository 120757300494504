@use "./../helpers/mixins";
@use "./../helpers/variables";

/// <reference path="./../helpers/mixins" />
/// <reference path="./../helpers/variables" />

.apollo-drag-and-drop {
    cursor: grab;
}

.cdk-drop-list-dragging {
    .apollo-drag-and-drop {
        cursor: grabbing;
    }
}

.apollo-drag-placeholder {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow:
        0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);

    &.dotted {
        border: dotted 2px;
        min-height: 28px;
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
}
