/* Palettes defined with color picking tool provided by Google (https://material.io/design/color/the-color-system.html#tools-for-picking-colors) */
@use "sass:map";
@use "@angular/material" as mat;

@use "./button-theme" as buttonTheme;
@use "./side-nav-theme" as sideNav;
@use "./notification-theme.scss" as notificationTheme;
@use "./paginated-grid-theme" as paginatedGridTheme;
@use "./primary-theme" as primaryTheme;

@include mat.core();

:root {
    // Font
    --font-size: 0.9rem;
}

// Colors
$alert: #{mat.get-theme-color(notificationTheme.$light-theme, secondary, 50)};
$accent: #{mat.get-theme-color(notificationTheme.$light-theme, tertiary, 50)};
$border-color: #{mat.get-theme-color(primaryTheme.$light-theme, neutral, 60)};
$disabled: rgba(0, 0, 0, 0.65);
$element-background: #{mat.get-theme-color(primaryTheme.$light-theme, primary, 100)};
$error-color: #{mat.get-theme-color(primaryTheme.$light-theme, error, 50)};
$link: #{mat.get-theme-color(primaryTheme.$light-theme, primary, 60)};
$page-background: #{mat.get-theme-color(primaryTheme.$light-theme, neutral, 96)};
$positive: #{mat.get-theme-color(notificationTheme.$light-theme, primary, 50)};
$primary: #{mat.get-theme-color(primaryTheme.$light-theme, primary, 40)};
$primary-darker: #{mat.get-theme-color(primaryTheme.$light-theme, primary, 25)};
$primary-lighter: #{mat.get-theme-color(primaryTheme.$light-theme, primary, 70)};
$system-text: #{mat.get-theme-color(primaryTheme.$light-theme, neutral, 6)};

body {
    @include mat.core-theme(primaryTheme.$light-theme);
    @include mat.all-component-themes(primaryTheme.$light-theme);

    // Button
    @include mat.button-density(0);

    // Button toggle
    @include mat.button-toggle-density(-4);
    --mat-standard-button-toggle-shape: 15px;
    --mat-standard-button-toggle-background-color: #{$element-background};

    // Calendar
    mat-calendar {
        @include mat.icon-button-density(-3);
    }

    // Dialog
    --mdc-dialog-container-color: #{$page-background};

    // Drawer
    mat-drawer {
        --mat-sidenav-container-background-color: #{$element-background};
    }

    // Form Field
    @include mat.form-field-density(-5);

    mat-form-field {
        background-color: #{$element-background};
    }

    // Menu
    --mat-menu-container-color: #{$element-background};

    // Select
    --mat-select-panel-background-color: #{$element-background};
    --mat-option-focus-state-layer-color: #{mat.get-theme-color(primaryTheme.$light-theme, neutral, 80)};
    --mat-option-hover-state-layer-color: #{mat.get-theme-color(primaryTheme.$light-theme, neutral, 87)};

    // Table
    --mat-table-background-color: #{$element-background};

    // Toolbar
    --mat-toolbar-container-background-color: #{$page-background};

    // Custom
    @include buttonTheme.button-theme(primaryTheme.$light-theme);
    @include paginatedGridTheme.paginated-grid-theme(primaryTheme.$light-theme);
    @include sideNav.side-nav-theme(primaryTheme.$light-theme);

    app-code-management .content-column,
    .widget-options-menu,
    iframe {
        background-color: #{$element-background};
    }

    app-image-picker,
    .login-container,
    .side-area {
        background-color: #{$page-background};
    }

    .link {
        &:hover {
            color: #{$link};
        }
    }

    mat-tree-node {
        .selected-button {
            color: $element-background;
            background-color: $primary;
        }
    }

    &.dark {
        mat-progress-bar {
            background-color: #{mat.get-theme-color(primaryTheme.$light-theme, neutral, 10)};
        }
    }
}

.positive {
    @include mat.icon-color(notificationTheme.$light-theme, $color-variant: primary);
}

.warn {
    @include mat.icon-color(notificationTheme.$light-theme, $color-variant: secondary);
}

.error {
    @include mat.icon-color(notificationTheme.$light-theme, $color-variant: error);
}

.warn-badge {
    @include mat.badge-color(notificationTheme.$light-theme, $color-variant: secondary);
}

.alert-button {
    @include mat.button-color(notificationTheme.$light-theme, $color-variant: secondary);
}

.secondary-button {
    @include mat.button-color(primaryTheme.$light-theme, $color-variant: secondary);
}

.tertiary-button {
    @include mat.button-color(primaryTheme.$light-theme, $color-variant: tertiary);
}

.error-button {
    @include mat.button-color(primaryTheme.$light-theme, $color-variant: error);
}