@use "helpers/functions";
@use "helpers/mixins";
@use "helpers/variables";

body {
    --mat-menu-container-shape: #{variables.$border-radius};
}

.filter-menu {
    max-height: 50vh;
    max-width: unset !important;
    overflow: hidden !important;

    &.large {
        width: 500px;

        .mat-mdc-menu-content {
            @include mixins.flex;
        }
    }

    &.row {
        .mat-mdc-menu-content {
            @include mixins.flex;
            overflow: hidden;
        }
    }

    form {
        @include mixins.flex($direction: column, $row-gap: functions.calc-space-standard-scale(2));
        @include mixins.padding-standard;
    }

    &.scrollable {
        .mat-mdc-menu-content {
            @include mixins.flex($direction: column);
            height: 100%;
            overflow: hidden;

            >div:not(.sub-menu):not(.title) {
                height: 100%;
                overflow: auto;
            }
        }
    }

    .mat-mdc-menu-content {
        height: 100%;
        overflow: auto;

        app-date-range,
        app-date-range-month {
            @include mixins.margin-standard-large(true, true, false, true);
            @include mixins.margin-standard-small($bottom: true);
        }

        mat-checkbox {
            width: 100%;
        }

        mat-divider {
            @include mixins.margin-standard-small(true, false, true, false);
        }

        .sub-menu {
            @include mixins.flex($align-items: center, $column-gap: functions.calc-space-standard-scale(0.5));
            @include mixins.padding-standard;
        }

        .title {
            @include mixins.padding-standard(false, true, false, true);
            font-weight: variables.$font-weight-bold;
        }
    }
}