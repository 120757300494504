@use "@angular/material" as mat;

@mixin paginated-grid-theme($theme) {
    app-paginated-grid,
    app-cmms-cures-management .paginated-table-container {
        $background-color: #{mat.get-theme-color($theme, primary, 100)};
        background-color: $background-color;
        --mat-paginator-container-background-color: #{$background-color};
        --mat-paginator-container-background-color: #{$background-color};
    }
}
