@use "./../themes/theme";

/// <reference path="./../themes/theme" />

// Border
$border-width: 1px;
$border-radius: 16px;

// Extend
%block-component {
    display: block;
    height: 100%;
}

%border-standard {
    border-style: solid;
    border-color: theme.$border-color;
    box-sizing: border-box;
}

%border-standard-rounded {
    @extend %border-standard;
    border-radius: $border-radius;
}

%clearfix {
    *zoom: 1;

    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}

%display-flex {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

%flex-component {
    @extend %display-flex;
    height: 100%;
}

%flex-column-component {
    @extend %display-flex;
    flex-direction: column;
    height: 100%;
}

// Animation
$standard-transition: 0.5s ease;

// Component
$min-input-width: 40px;

// Display
$box-shadow: 1px 1px 5px 0px theme.$border-color;
$disabled-opacity: 0.5;

// Font
$font-size-standard: var(--font-size);
$font-size-responsive: 1vmax;
$font-size-ratio: 1.2;
$font-weight-light: 300;
$font-weight-semibold: 500;
$font-weight-bold: 700;
$line-height: 28px;

// Icon
$icon-size: 20px;
$icon-size-responsive: 1vmax;

// Margin/Padding
$space-standard: 8px;

// Screen sizes
$medium-screen-width: 1360px;
$large-screen-width: 1920px;
