@use "helpers/functions";
@use "helpers/mixins";
@use "helpers/variables";
@use "themes/theme";

// mat-badge
.mat-badge-small {
    .mat-badge-content {
        @include mixins.font-scale("sm");
        left: functions.calc-space-standard-scale(-1) !important;
        top: functions.calc-space-standard-scale(-0.5) !important;
    }
}

// mat-button/icon
mat-icon {
    height: unset !important;
    width: unset !important;
}

// mat-button-toggle
.mat-button-toggle-button {
    app-icon {
        .mat-icon {
            margin-right: 0px !important;
        }
    }
}

// mat-calendar
mat-datepicker-content {
    @include mixins.border-standard;
}

mat-calendar {
    .mat-calendar-arrow {
        display: none;
    }

    &.disable-select {
        .mat-calendar-period-button {
            cursor: pointer;
            pointer-events: none;
        }
    }
}

// mat-date-range
.mat-date-range-input-container {
    width: 100%;
}

// mat-drawer
mat-drawer-container {
    height: 100%;

    mat-drawer {
        @include mixins.border-standard($right: 1px);
        background-color: theme.$page-background;
        --mat-sidenav-container-shape: 0px;
        --mat-expansion-container-background-color: #{theme.$element-background};
        border-right-color: theme.$border-color !important;
    }

    mat-expansion-panel {
        background-color: theme.$page-background;
    }

    .navigation {
        @include mixins.padding-standard(true, false, true, false);
        padding-right: functions.calc-space-standard-scale(3);
        padding-left: functions.calc-space-standard-scale(3);
        cursor: pointer;

        &.active {
            background-color: theme.$primary;
            color: theme.$element-background;
        }
    }
}

.mat-drawer-inner-container {
    @include mixins.flex($direction: column);
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
}

// mat-form-field (input)
mat-form-field {
    --mdc-outlined-text-field-label-text-size: var(--font-size);
    --mat-form-field-container-text-size: var(--font-size);
    --mat-select-trigger-text-size: var(--font-size);
    border-radius: 4px;
}

mat-option {
    --mat-option-label-text-size: var(--font-size);
}

.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-suffix {
    padding-left: variables.$space-standard !important;
    padding-right: variables.$space-standard !important;
}

.mat-mdc-form-field-infix {
    @include mixins.flex;

    .mdc-text-field__input {
        display: flex !important;
    }
}

// mat-menu
.mat-mdc-menu-content {
    @include mixins.border-standard-rounded;
}

// mat-select
.mat-mdc-select-arrow-wrapper {
    padding-left: functions.calc-space-standard-scale(0.5);
}

// mat-slider
.mdc-slider .mdc-slider__value-indicator {
    transform: none !important;
}

// mat-slide-toggle
button.mdc-switch {
    transform: scale(0.8);
}

// mat-snackbar
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    min-width: 225px !important;
}

// mat-tab
.mat-mdc-tab-links {
    border-width: 0px;
    border-bottom-width: variables.$border-width;
    border-style: solid;

    .mdc-tab.mat-mdc-tab-link.active {
        background-color: theme.$primary;

        .mdc-tab__text-label {
            color: theme.$element-background !important;
        }
    }
}

// mat-table
.mat-sort-header-content {
    text-align: left !important;
}

// mat-tooltip
.mdc-tooltip__surface {
    text-align: left !important;
    overflow: auto !important;
    height: 100%;
}

// mat-tree
mat-tree {
    background-color: inherit !important;
}

// mat-expansion-panel
mat-expansion-panel.override-material-style {
    border-width: 0px;
    border-radius: 0px !important;
    box-shadow: none !important;

    mat-expansion-panel-header {
        @include mixins.padding-standard-large(false, true, false, true);
    }

    .mat-expansion-panel-body {
        padding: 0px;
    }
}