@use "helpers/functions";
@use "helpers/mixins";
@use "helpers/variables";
@use "themes/theme";

.wiki-window {
    @include mixins.border-standard-rounded;
    box-shadow: 2px 2px 2px theme.$border-color;
    border-color: theme.$disabled;
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60vw;
    height: 65vh;
    min-width: 920px;
    min-height: 460px;
    z-index: 100;
    overflow: hidden;
}
