@use "helpers/mixins";
@use "helpers/variables";

.charting-component {
    @extend %block-component;
    overflow: hidden;

    .advanced-pie-legend {
        .total-label {
            @include mixins.margin-standard($bottom: true);
            font-size: 18px;
        }
    }
}
