@use "./../helpers/mixins";
@use "./../themes/theme";
@use "./../helpers/variables";

/// <reference path="./../helpers/mixins" />
/// <reference path="./../themes/theme" />
/// <reference path="./../helpers/variables" />

body {
    @include mixins.font-scale;
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%;
}
