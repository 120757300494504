$fade: opacity 300ms;

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        opacity: 0;
    }

    40% {
        -webkit-transform: scale(1);
        opacity: 1;
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes fadeInAndDown {
    0% {
        opacity: 0;
        bottom: 1em;
    }

    100% {
        opacity: 1;
        bottom: 0;
    }
}
