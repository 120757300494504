@use "@angular/material" as mat;

@mixin side-nav-theme($theme) {
    app-header-bar {
        --mat-toolbar-container-background-color: #{mat.get-theme-color($theme, primary, 100)};
        --mat-toolbar-container-text-color: #{mat.get-theme-color($theme, primary, 40)};
    }

    app-side-nav {
        --mat-sidenav-container-background-color: #{mat.get-theme-color($theme, neutral, 100)};
        --mat-sidenav-content-background-color: #{mat.get-theme-color($theme, neutral, 96)};

        .sidenav-icon-button {
            &.active {
                background-color: mat.get-theme-color($theme, primary, 40);
                color: mat.get-theme-color($theme, primary, 100);
            }

            &:hover:not(.active) {
                background-color: mat.get-theme-color($theme, primary, 90);
            }
        }
    }

    app-sidenav-hidden-menu {
        background-color: mat.get-theme-color($theme, primary, 40);
        color: mat.get-theme-color($theme, primary, 100);

        &:hover {
            background-color: mat.get-theme-color($theme, primary, 90);
        }
    }

    &.dark {
        app-header-bar {
            --mat-toolbar-container-background-color: #{mat.get-theme-color($theme, neutral, 10)};
            --mat-toolbar-container-text-color: #{mat.get-theme-color($theme, primary, 100)};
            --mdc-icon-button-icon-color: #{mat.get-theme-color($theme, primary, 100)};
        }

        app-side-nav {
            --mat-sidenav-container-background-color: #{mat.get-theme-color($theme, neutral, 10)};
            --mat-sidenav-container-text-color: #{mat.get-theme-color($theme, primary, 100)};

            .sidenav-logo {
                img {
                    background-color: #{mat.get-theme-color($theme, neutral, 96)};
                }
            }

            .sidenav-icon-button {
                &:hover:not(.active) {
                    background-color: mat.get-theme-color($theme, primary, 70);
                }
            }
        }
    }

    // @media (prefers-color-scheme: dark) {
    //     app-header-bar {
    //         --mat-toolbar-container-background-color: #{mat.get-theme-color($theme, neutral, 10)};
    //         --mat-toolbar-container-text-color: #{mat.get-theme-color($theme, primary, 100)};
    //         --mdc-icon-button-icon-color: #{mat.get-theme-color($theme, primary, 100)};
    //     }

    //     app-side-nav {
    //         --mat-sidenav-container-background-color: #{mat.get-theme-color($theme, neutral, 10)};
    //         --mat-sidenav-container-text-color: #{mat.get-theme-color($theme, primary, 100)};

    //         .sidenav-icon-button {
    //             &:hover {
    //                 background-color: mat.get-theme-color($theme, primary, 70);
    //             }
    //         }
    //     }
    // }
}
