@use "helpers/functions";
@use "helpers/mixins";
@use "helpers/variables";
@use "themes/theme";

.custom-chart-tooltip {
    &.series {
        text-align: left;

        >div {
            @include mixins.flex($direction: column, $row-gap: functions.calc-space-standard-scale(0.5));
            @include mixins.margin-standard(true, false, true, false);

            >div {
                @include mixins.flex($column-gap: functions.calc-space-standard-scale(0.5));

                span:first-child {
                    border-radius: 2px;
                }
            }

            >span:last-child {
                margin-left: 22px;
            }
        }
    }
}

.widget-component {
    @extend %block-component;
    overflow: hidden;

    .summary-container {
        @include mixins.flex($direction: column, $align-items: center, $justify-content: flex-start, $flex-grow: 1, $row-gap: functions.calc-space-standard-scale(0.5));
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        &.empty {
            height: 100%;
            text-align: center;
            justify-content: center;
        }

        mat-divider {
            margin-bottom: 6px;
            width: 100%;
        }

        >div {
            @include mixins.flex($direction: column, $align-items: center, $justify-content: center, $flex-wrap: wrap);
            width: 100%;

            >div:last-child {
                @include mixins.font-scale("sm");
            }
        }

        .title {
            @include mixins.flex($justify-content: space-between);
        }
    }

    .footer {
        @include mixins.flex($direction: column, $align-items: center, $row-gap: functions.calc-space-standard-scale(0.25));
        @include mixins.padding-standard-small($top: true);
        text-align: center;

        .title {
            font-weight: variables.$font-weight-bold;
        }
    }

    --mat-table-header-headline-weight: #{variables.$font-weight-bold};
    --mat-table-footer-supporting-text-weight: #{variables.$font-weight-bold};
    --mat-table-row-item-container-height: #{functions.calc-space-standard-scale(4)};

    .widget-table-container {
        height: 100%;
        overflow: auto;

        table {

            .mat-mdc-cell,
            .mat-mdc-footer-cell,
            .mat-mdc-header-cell {

                &.right-align {
                    text-align: right;
                    white-space: nowrap;
                }
            }

            .mat-column-empty {
                width: 100%;
            }
        }
    }

    app-date-year {
        width: calc(#{variables.$min-input-width} * 2.5);
    }
}