// Themes
@use "themes/theme";

// Helpers
@use "helpers/functions";
@use "helpers/mixins";
@use "helpers/variables";

// Vendors
@use "vendor/gridster";
@use "vendor/mat";
@use "vendor/quill-editor";

// Base
@use "base/animations";
@use "base/drag";
@use "base/reset";
@use "base/typography";

// Layout
@use "layouts/form";
@use "layouts/scrollbar";
@use "layouts/tooltip";

// Components
@use "components/buttons";
@use "components/charting";
@use "components/dashboard";
@use "components/dialog";
@use "components/mat-menu";
@use "components/widgets";
@use "components/wiki";

.block-component {
    @extend %block-component;
}

.disabled {
    pointer-events: none;
    opacity: variables.$disabled-opacity;
}

.grabbable:hover {
    cursor: move;
}

.hidden {
    display: none !important;
}

.invisible {
    visibility: hidden;

    * {
        visibility: hidden;
    }
}

.vertical-scrollbar-padding {
    padding-right: 10px !important;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

mat-icon {
    &.x-small {
        @include mixins.icon-scale("xs");
    }

    &.small {
        @include mixins.icon-scale("sm");
    }

    &.medium {
        @include mixins.icon-scale;
    }
}