@use "helpers/functions";
@use "helpers/mixins";
@use "helpers/variables";
@use "themes/theme";

.dashboard-toolbar {
    @include mixins.border-standard($bottom: 1px);
    @include mixins.flex($align-items: center, $justify-content: space-between, $column-gap: variables.$space-standard);
    @include mixins.padding-standard(true, false, true, false);
    @include mixins.padding-standard-large($left: true);
    padding-right: 22px;
    min-height: 64px;
    background-color: theme.$primary;
    color: theme.$element-background;
}
