@use "./../helpers/functions";
@use "./../helpers/mixins";
@use "./../helpers/variables";
@use "./../themes/theme";

/// <reference path="./../helpers/variables" />
/// <reference path="./../themes/theme" />

.button-container {
    @include mixins.flex($justify-content: center, $column-gap: functions.calc-space-standard-scale(2));
    @include mixins.flex-item-position-end("top");
    width: 100%;

    > button,
    app-split-button {
        width: 15%;
        min-width: 225px;
    }
}

button {
    &.inactive-toggle {
        font-size: 24px !important;
        height: 28px !important;
        width: 24px !important;
        margin-left: functions.calc-space-standard-scale(0.5) !important;

        mat-icon {
            &.toggle-button {
                vertical-align: text-bottom !important;
            }
        }
    }

    &.disabled {
        cursor: auto;
        color: theme.$disabled !important;
        opacity: 1;
    }

    &.full {
        width: 100%;
        min-width: 100%;
    }
}
