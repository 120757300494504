@use "./../helpers/functions";
@use "./../helpers/mixins";
@use "./../helpers/variables";
@use "./../themes/theme";

/// <reference path="./../helpers/functions" />
/// <reference path="./../helpers/mixins" />
/// <reference path="./../helpers/variables" />

.form-container {
    @include mixins.flex($direction: column, $row-gap: functions.calc-space-standard-scale(2));
    width: 80%;
    height: 100%;
    margin-bottom: functions.calc-space-standard-scale(3);
    overflow: hidden;
    align-self: center;

    @include mixins.small-width {
        width: 90%;
    }

    @include mixins.large-width {
        width: 70%;
    }
}

.form-side-sheet {
    height: 100%;

    .form-container {
        width: 100%;
    }
}

$gap: variables.$space-standard;

form.form {
    @include mixins.flex($align-items: center, $flex-wrap: wrap, $column-gap: $gap, $row-gap: $gap);
    @include mixins.padding-standard-large;
    @include mixins.border-standard-rounded;
    @include mixins.font-scale("sm");
    background-color: theme.$element-background;
    align-self: center;
    max-height: 100%;
    width: 100%;
    overflow: auto;

    .form-row {
        @include mixins.flex($align-items: center, $flex-wrap: wrap, $column-gap: $gap, $row-gap: $gap);
        flex-grow: 1;
        width: 100%;
    }

    .form-col {
        @include mixins.flex($align-items: baseline, $column-gap: $gap);
        @include mixins.form-col-width(12);

        &.col-2 {
            @include mixins.form-col-width(6);
        }

        &.col-3 {
            @include mixins.form-col-width(4);
        }

        &.col-4 {
            @include mixins.form-col-width(3);
        }

        &.col-5 {
            @include mixins.form-col-width(calc(12 / 5));
        }

        &.col-6 {
            @include mixins.form-col-width(2);
        }

        &.col-7 {
            @include mixins.form-col-width(calc(12 / 7));
        }

        &.col-8 {
            @include mixins.form-col-width(1.5);
        }

        &.col-9 {
            @include mixins.form-col-width(calc(12 / 9));
        }

        &.col-10 {
            @include mixins.form-col-width(calc(12 / 10));
        }

        &.col-11 {
            @include mixins.form-col-width(calc(12 / 11));
        }

        &.col-12 {
            @include mixins.form-col-width(1);
        }

        // Pretend element has content so it takes up appropriate horizontal space
        &:empty {
            content: "";
            align-self: center;
        }
    }
}

form.form-row {
    .form-col {
        min-width: unset !important;
    }
}
