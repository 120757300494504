@use "helpers/functions";
@use "helpers/mixins";
@use "helpers/variables";

.dialog-standard {
    max-width: 700px;
    width: 45%;

    .close-button {
        @include mixins.position-absolute($bottom: auto, $left: auto);
    }

    .dialog-icon {
        @include mixins.margin-standard($right: true);
        float: left;
    }
}

.dialog-responsive {
    width: 65%;
    height: 80%;

    .mat-dialog-container {
        padding: functions.calc-space-standard-scale(2) functions.calc-space-standard-scale(1.5);
    }
}

.dialog-responsive-small {
    height: auto;
    max-height: 30vw !important;
    width: auto;
    max-width: 30vw !important;
}
