@use "./../helpers/mixins";
@use "./../themes/theme";

.custom-chart-tooltip {
    @include mixins.padding-standard;

    .label {
        @include mixins.font-scale;
    }

    .value {
        @include mixins.font-scale("lg");
    }
}

.mat-tooltip {
    @include mixins.font-scale("sm");
    padding: 6px 12px;
}

.mat-tooltip-fulllength {
    max-width: none !important;
}

.multiline-tooltip-explicit {
    white-space: pre;
}

.multiline-tooltip {
    white-space: pre-line;
}
