@use "themes/theme";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

.ql-container {
    font-family: unset !important;
    font-size: unset !important;
    height: 100% !important;
    overflow: auto !important;
}

quill-editor {
    .ql-container {
        background-color: theme.$element-background;
        border-radius: 5px;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
    }
}

.ql-editor.ql-blank::before {
    font-style: unset !important;
}
